import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import Header3 from './components/header3/header3';
import FloatingWhatsapp from './components/floating-whatsapp/floatingwhatsapp';


export default function Content() {
  return (
    <>
    
    <SideNavBarLayout title={appInfo.title}>
      <Header3/>  
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
      <FloatingWhatsapp/>
      <Footer/>
    
      </SideNavBarLayout>
      
    </>
   
  );
}

