
import * as React from 'react';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';

export default function FloatingWhatsapp() {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        '& > :not(style)': { m: 1 },
      }}
    >
      <a href='https://wa.me/5565981676400' target='_blank'>
        <img src="img/whatsapp.png"/>
      </a>
        
     
      
    </Box>
  );
}