import React from 'react';

import './tecnologias.scss';






export default function Tecnologias({ ...rest }) {
  return <section className='tecnologia' id="section-1">
    <div id="div-1">
      <div id="div-2">
        <div id="div-3">
          <article id="article-1">
            <h2 id="h2-1">Conheça aqui algumas das tecnologias que desenvolvemos e oferecemos para sua empresa:</h2>
          </article>
        </div>

        <div id="div-4">
          <div id="div-5">
            <a href="/#satesb" id="a-1">
              <img id="img-1" src="img/NEUMAN.jpg" />

              <p id="p-1">Teste de Bombeamento</p>
            </a>
          </div>

          <div id="div-6">
            <a href="/#satesb" id="a-2">
              <img id="img-2" src="img/DESENHO_POCO.jpg" />

              <p id="p-2">Desenho Construtivo</p>
            </a>
          </div>

          <div id="div-7">
            <a href="/#satesb" id="a-3">
              <img id="img-3" src="img/ANALISE_QUIMICA.jpg" />

              <p id="p-3">Analise Quimica</p>
            </a>
          </div>

          <div id="div-8">
            <a href="/#satesb" id="a-4">
              <img id="img-4" src="img/BOLETIM_SPT.jpg" />

              <p id="p-4">Boletim de SPT</p>
            </a>
          </div>

          <div id="div-9">
            <a href="/#satesb" id="a-5">
              <img id="img-5" src="img/MAPA3D.png" />

              <p id="p-5">Mapas 3D de Poços</p>
            </a>
          </div>
          <div id="div-9">
            <a href="/#satesb" id="a-5">
              <img id="img-5" src="img/MAPAIBGE.png" />

              <p id="p-5">Mapas IBGE</p>
            </a>
          </div>
          <div id="div-9">
            <a href="/#satesb" id="a-5">
              <img id="img-5" src="img/MAPASIAGAS.png" />

              <p id="p-5">Mapas SIAGAS</p>
            </a>
          </div>

          <div id="div-10">
            <a href="/#satesb" id="a-6">
              <img id="img-6" src="img/RELATORIOS.png" />

              <p id="p-6">Relatórios</p>
            </a>
          </div>

          <div id="div-11">
            <a href="/#satesb" id="a-7">
              <img id="img-7" src="img/PERMEABILIDADE_SOLO.jpg" />

              <p id="p-7">Permeabilidade</p>
            </a>
          </div>

          <div id="div-12">
            <a href="/#satesb" id="a-8">
              <img id="img-8" src="img/INTERFERENCIA.png" />

              <p id="p-8">Teste de Interferencia</p>
            </a>
          </div>
          <div id="div-12">
            <a href="/#satesb" id="a-8">
              <img id="img-8" src="img/bombas.png" />
              <p id="p-8">Catalogos de Bombas</p>
            </a>
          </div>
          <div id="div-12">
            <a href="/#satesb" id="a-8">
              <img id="img-8" src="img/MonitoramentoPoco.png" />
              <p id="p-8">Monitoramento de Poços</p>
            </a>
          </div>
          <div id="div-12">
            <a href="/#satesb" id="a-8">
              <img id="img-8" src="img/CALCULADORA.png" />
              <p id="p-8">Calculadoras</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


;
}
