import React, { useState, createContext, useContext, useEffect } from 'react';
import {  ModuloLogin } from '../pages';
import type { NavigationContextType } from '../types';
import {  useAuth } from '../contexts/auth';

const NavigationContext = createContext<NavigationContextType>({} as NavigationContextType);
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props: React.PropsWithChildren<unknown>) {
  const [navigationData, setNavigationData] = useState({ currentPath: '' });

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
}

function withNavigationWatcher(Component: React.ElementType, path: string, publicRoute: boolean) {
  
  const WrappedComponent = function (props: Record<string, unknown>) {
    const {user} = useAuth();
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      setNavigationData!({ currentPath: path });
    }, [setNavigationData]);
    if (publicRoute) {
      return <Component {...props} />;
    }
    if (user) {
      return <Component {...props} />;
    }
    return <ModuloLogin  {...props}/>
  }
  return <WrappedComponent />;
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher
}
