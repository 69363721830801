import React from 'react';
import './home.scss';
import Tecnologias from '../../components/tecnologias/tecnologias';
import Slider from '../../components/Slider/Slider';

export default function ModuloHome() {
  return (
    <React.Fragment>
     
 
    <Slider/>
   
      <Tecnologias/>
  
   
    </React.Fragment>
)}
