import React from 'react';
import './modifypass.scss';
import ResetPasswordForm from '../../components/reset-password-form/ResetPasswordForm';
import { DuoCard } from '../../layouts';

export default function ModuloModifyPass() {
  return (
    
      <DuoCard title="Alteração de Senha">
      <ResetPasswordForm/>
      </DuoCard>
      
    
)}
