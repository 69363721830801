import 'aoti/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'aoti-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { DadosProvider, useDados } from './contexts/dados';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';

function App() {
  const { user, loading } = useAuth();
  const { ufs, horarioAtendimento, PingIn } = useDados();


  React.useEffect(() => {
   
    if (!loading) {
      console.log('kkk');
      PingIn();
    }
    
  }, [PingIn, loading]);
  
  if (loading) {
    return <LoadPanel visible={true} />;
  }

   if (user) {
     return <Content />;
   }
 
  return <Content />;//<UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <DadosProvider>
       <AuthProvider>
        <NavigationProvider>
         
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
       </AuthProvider>
      </DadosProvider>
    </Router>
  );
}
