
import { ModuloHomePage, ModuloDownload, 
    ModuloCadastreSe, ModuloComprar, ModuloNovidades, ModuloLigamos, ModuloLogin, ModuloTreinamento, 
    ModuloCertificado, ModuloEventos, ModuloImprensa, ModuloTrabalheConosco, ModuloSuporte,
    ModuloSatesb, ModuloTermo, ModuloPrivacidade, ModuloRecoverPass, ModuloMeusDados,
    ModuloModifypass
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';



const routes = [
    {
        path: '/cadastrese',
        public: true,
        element: ModuloCadastreSe
    },
    {
        path: '/comprar',
        public: true,
        element: ModuloComprar
    },
    {
        path: '/download',
        public: true,
        element: ModuloDownload
    },
    {
        path: '/novidades',
        public: true,
        element: ModuloNovidades
    },
    {
        path: '/ligamos',
        public: true,
        element: ModuloLigamos
    },
    {
        path: '/termo',
        public: true,
        element: ModuloTermo
    },
    {
        path: '/privacidade',
        public: true,
        element: ModuloPrivacidade
    },
    {
        path: '/satesb',
        public: true,
        element: ModuloSatesb
    },
    {
        path: '/certificado',
        public: true,
        element: ModuloCertificado
    },
    {
        path: '/eventos',
        public: true,
        element: ModuloEventos
    },
    {
        path: '/imprensa',
        public: true,
        element: ModuloImprensa
    },
    {
        path: '/trabalhe-conosco',
        public: true,
        element: ModuloTrabalheConosco
    },
    {
        path: '/treinamento',
        public: true,
        element: ModuloTreinamento
    },
    {
        path: '/suporte',
        public: true,
        element: ModuloSuporte
    },
    {
        path: '/login',
        public: true,
        element: ModuloLogin
    },
    {
        path: '/recoverpass',
        public: true,
        element: ModuloRecoverPass
    },
    {
        path: '/meusdados',
        public: false,
        element: ModuloMeusDados
    },
    {
        path: '/modifypass',
        public: false,
        element: ModuloModifypass
    },
    {
        path: '/home',
        public: true,
        element: ModuloHomePage
    }
];


export default routes.map(route => {
   
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path, route.public)
    };
   

    
   
});
