import React, { useEffect,  useCallback} from 'react';
import {  useNavigate } from 'react-router-dom';
import './meusdados.scss';
import Form from 'aoti-react/form';
import Button from 'aoti-react/button';
import {  useAuth } from '../../contexts/auth';

export default function ModuloMeusDados() {
  const navigate = useNavigate();
  const {account, accountIn } = useAuth();
  
  useEffect(() => {
    console.log('useEffect:');
    async function loadAccount() {
      const result = await accountIn();
      if (!result.isOk) {
        console.log('erro:',result);
      }    
      console.log('useEffect: ok',result);  
    }
    loadAccount();
  },[])

  const AlterPassClick = useCallback(() => {
    navigate('/modifypass');
  }, [navigate]);


  return (
    <React.Fragment>
      <h2 className={'content-block'}>Meus Dados</h2>
      <div className={'content-block dx-card responsive-paddings'}>  

        <div className={'content-block dx-card responsive-paddings'}>
        <Form
            id={'form'}
            formData={account}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
          >
         
          </Form>
          
        </div>
        <div className={'content-block dx-card responsive-paddings'}>
          <Button  text="Alterar Senha" onClick={AlterPassClick}   type="success"           stylingMode="outlined"/>
        </div>
      </div>  
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
