import React from 'react';
import './Footer.scss';

export default function Footer({ ...rest }) {
  return <div className='footer' >
    <footer id="footer-1">
    <div id="div-1">
      <div id="div-2">
        <div id="div-3">
          <div id="div-4">
            <a href="https://www.aoti.com/" id="a-1">
              <img id="img-1" src="img/logo.png" />
            </a>
          </div>

          <div id="div-5">
            <h5 id="h5-1">SOBRE A AOTI</h5>

            <ul id="ul-1">
              <li id="li-1">
                <a href="/#trabalhe-conosco/" id="a-2">
                  TRABALHE CONOSCO
                </a>
              </li>              

              <li id="li-3">
                <a href="/#eventos" id="a-4">
                  EVENTOS
                </a>
              </li>


              <li id="li-5">
                <a href="/#treinamento" id="a-6">
                  TREINAMENTOS
                </a>
              </li>

              <li id="li-5">
                <a href="/#certificado" id="a-6">
                  CERTIFICADO
                </a>
              </li>

              
            </ul>
          </div>

          <div id="div-6">
            <h5 id="h5-2">LINKS ÚTEIS</h5>

            <ul id="ul-2">
              <li id="li-12">
                <a href="https://www.youtube.com/@AOTIOFICIAL" target="_blank" id="a-13">
                  YOUTUBE
                </a>
              </li>

              <li id="li-13">
                <a href="https://www.facebook.com/aotierp"  target="_blank" id="a-14">
                  FACEBOOK
                </a>
              </li>

              <li id="li-13">
                <a href="https://www.instagram.com/aotioficial/"  target="_blank" id="a-14">
                  INSTAGRAM
                </a>
              </li>

              <li id="li-14">
                <a href="/#suporte" id="a-15">
                  SUPORTE
                </a>
              </li>
              <li id="li-14">
                <a href="/#download" id="a-15">
                  DOWNLOAD
                </a>
              </li>          

              
            </ul>
          </div>

          <div id="div-7">
            <h5 id="h5-3">FALE CONOSCO</h5>

            <p id="p-1">+55 65 981676-4000</p>

            <div id="div-8">

              <a href="https://www.youtube.com/@AOTIOFICIAL" target='_blank' id="a-25">
                <img id="img-2" src="img/youtube.png"  />
              
              </a>

              <a href="https://www.facebook.com/aotierp" target='_blank' id="a-26">
              <img id="img-2" src="img/facebook.png" />
              </a>
                

              <a href="https://www.instagram.com/aotioficial/" target='_blank' id="a-27">
              <img id="img-2" src="img/instagram.png" />
              </a>
            </div>

            <ul id="ul-3">
              <li id="li-23">
                <a href="/#imprensa" id="a-28">
                  Assessoria de Imprensa
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  </div>
;
}
