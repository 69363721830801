import React from 'react';
import './privacidade.scss';
import { DuoCard } from '../../layouts';


export default function ModuloPrivacidade() {
  return (
    <React.Fragment>
      <DuoCard >
      <div className="privacidade">
        <h1>Política de Privacidade</h1>
        <p>Sua privacidade é importante para nós. Esta política de privacidade explica quais informações pessoais coletamos, como as utilizamos e como as protegemos.</p>

        <h2>Informações que Coletamos</h2>
        <p>Coletamos vários tipos de informações em conexão com os serviços que oferecemos, incluindo:</p>
        <ul>
            <li>Informações que você nos fornece diretamente, como seu nome, endereço de e-mail e outras informações de contato.</li>
            <li>Informações que coletamos automaticamente, como dados de navegação, cookies e endereços IP.</li>
        </ul>

        <h2>Como Usamos as Informações</h2>
        <p>Usamos as informações que coletamos para:</p>
        <ul>
            <li>Fornecer, operar e manter nossos serviços.</li>
            <li>Melhorar, personalizar e expandir nossos serviços.</li>
            <li>Entender e analisar como você utiliza nossos serviços.</li>
            <li>Desenvolver novos produtos, serviços, funcionalidades e funcionalidades.</li>
        </ul>

        <h2>Compartilhamento de Informações</h2>
        <p>Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:</p>
        <ul>
            <li>Com seu consentimento.</li>
            <li>Para cumprir obrigações legais.</li>
            <li>Para proteger nossos direitos e propriedades.</li>
            <li>Para prevenir fraudes ou outras atividades ilegais.</li>
        </ul>

        <h2>Segurança das Informações</h2>
        <p>Adotamos medidas de segurança técnicas e organizacionais para proteger suas informações pessoais contra acesso não autorizado, destruição, perda, alteração, divulgação ou uso indevido.</p>

        <h2>Seus Direitos</h2>
        <p>Você tem o direito de acessar, corrigir, atualizar ou solicitar a exclusão de suas informações pessoais. Para exercer esses direitos, entre em contato conosco através dos canais fornecidos abaixo.</p>

        <h2>Alterações a Esta Política de Privacidade</h2>
        <p>Podemos atualizar esta política de privacidade periodicamente. Notificaremos você sobre quaisquer mudanças publicando a nova política de privacidade nesta página.</p>

        <h2>Contato</h2>
        <p>Se você tiver qualquer dúvida sobre esta política de privacidade, entre em contato conosco:</p>
        <ul>
            <li>Email: contato@aoti.com</li>
        </ul>
    </div>
      </DuoCard>
      
    
    </React.Fragment>
)}
