import React from 'react';
import './ligamos.scss';
import LigamosForm from '../../components/ligamos-form/LigamosForm';

export default function ModuloLigamos() {
  return (
<React.Fragment>
      <LigamosForm/>
    </React.Fragment>
)}
