
import axios from 'axios';
import type {UFS, HorarioAtendimento,TLigamos, TCountAcess} from '../types';


export async function UFsIn() : Promise<{isOk: boolean, data?: UFS}> {
   
    try {
        const heands = {
            headers: {
                'Content-Type': 'application/json' 
            }
        }
        const response = await axios.get(process.env.REACT_APP_API+'/ufs', heands )

            
        return {
            isOk: true,
            data: response.data
        };

    } catch (err) {
        return {
            isOk: false,
        };
    } 
   
}
export  function Ping() : void {
   
    try {
        const heands = {
            headers: {
                'Content-Type': 'application/json' 
            }
        }
        axios.get(process.env.REACT_APP_API+'/ping', heands )

            
       

    } catch (err) {
    } 
   
}
export async function CountAcess() : Promise<{isOk: boolean, data: TCountAcess}> {
   
    try {
        const heands = {
            headers: {
                'Content-Type': 'application/json' 
            }
        }
        const response = await axios.get(process.env.REACT_APP_API+'/countacess', heands )

            
        return {
            isOk: true,
            data: response.data
        };

    } catch (err) {
        return {
            isOk: false,
            data: {qtde: 0}
        };
    } 
   
}
export async function HorarioAtendimentoIn() : Promise<{isOk: boolean, data?: HorarioAtendimento}> {
   
    try {
        const heands = {
            headers: {
                'Content-Type': 'application/json' 
            }
        }
        const response = await axios.get(process.env.REACT_APP_API+'/horarioatendimento', heands )

            
        return {
            isOk: true,
            data: response.data
        };

    } catch (err) {
        return {
            isOk: false,
        };
    } 
   
}

export async function LigamosIn(cad: TLigamos) : Promise<{isOk: boolean, message?: string}> {
    try {
      const heands = {
        headers: {
          'Content-Type': 'application/json' 
        }
        
      }
        await axios.post(process.env.REACT_APP_API+'/ligamos', {...cad},heands )
  
          return {
            isOk: true,
            message: "Enviado com sucesso"
          };    
    }  catch (err:any) {
      return {
        isOk: false,
        message: err.response.data.error
      };
    }
  }
  