import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser,getAccount, ResetPassword, signIn as sendSignInRequest } from '../api/auth';
import type { User, AuthContextType, TAccount } from '../types';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [account, setAccount] = useState<TAccount>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const result1 = await sendSignInRequest(email, password);
    let vuser:User;
    if (result1.isOk) {
      if (result1.data) {
        vuser = result1.data; // Assign the value directly to the "user" variable
        setUser(vuser);
        
        const result2 = await getAccount(vuser.token);
        if (result2.isOk) {
          setAccount(result2.data);
        }

        console.log(`logado:`,result2.data);
      }
      
    }

    return result1;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);

  const accountIn = useCallback(async () => {
    console.log(`user 1`,user);
    if (!user) {
      console.log(`accountIn 1`);
      return { isOk: false };
    }
    if (user.token === undefined) {
      console.log(`accountIn 2`);
      return { isOk: false };
    }
    const result = await getAccount(user?.token);
    if (result.isOk) {
      console.log(`result.data:`,result.data);
      setAccount(result.data);
    }

    return result;
  }, []);

  const ResetPassIn = useCallback(async (password:string) => {
    if (!user) {
      return { isOk: false, message: 'Não esta logado'};
    }
    if (user.token === undefined) {
      return { isOk: false, message: 'Não esta logado' };
    }

    const result = await ResetPassword(user?.token,password);
    if (result.isOk) {
      return { isOk: true };
    }

    return result;
  }, []);


  return (
    <AuthContext.Provider value={{ user, account, signIn, signOut, accountIn, ResetPassIn, loading }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
