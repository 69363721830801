// dados.tsx
import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { 
  UFsIn as sendUFsIn, 
  HorarioAtendimentoIn as sendHorarioAtendimentoIn, 
  LigamosIn as sendLigamosIn,
  Ping as sendPing,
  CountAcess as sendCountAcessIn 

} from '../api/dados';
import type { UFS, DadosContextType, HorarioAtendimento, TLigamos, TCountAcess } from '../types';

const DadosContext = createContext<DadosContextType>({ loading: false } as DadosContextType);

function DadosProvider(props: React.PropsWithChildren<unknown>) {
  const [ufs, setUFs] = useState<UFS>();
  const [acessos, setAcessos] = useState<TCountAcess>();
  const [horarioAtendimento, setHorarioAtendimento] = useState<HorarioAtendimento>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result1 = await sendUFsIn();
      if (result1.isOk) {
        setUFs(result1.data);
      }
      const result2 = await sendHorarioAtendimentoIn();
      if (result2.isOk) {
        setHorarioAtendimento(result2.data);
      }
      setLoading(false);
    })();
  }, []);

  const UfsIn = useCallback(async () => {
    const result = await sendUFsIn();
    if (result.isOk) {
      setUFs(result.data);
    }
    return result;
  }, []);

  const CountAcessIn = useCallback(async () => {
    const result = await sendCountAcessIn();
    return result;
    
  }, []);

  const PingIn = useCallback(async () => {
    console.log("Ping In")
    sendPing();
  }, []);

  const HorarioAtendimentoIn = useCallback(async () => {
    const result = await sendHorarioAtendimentoIn();
    if (result.isOk) {
      setHorarioAtendimento(result.data);
    }
    return result;
  }, []);

  const LigamosIn = useCallback(async (cad: TLigamos) => {
    const result = await sendLigamosIn(cad);
    
    return result;
  }, []);

  return (
    <DadosContext.Provider value={{ ufs,acessos, horarioAtendimento, UfsIn, HorarioAtendimentoIn,LigamosIn, PingIn, CountAcessIn, loading }} {...props} />
  );
}

const useDados = () => useContext(DadosContext);

export { DadosProvider, useDados };
