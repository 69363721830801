import React from 'react';
import './novidades.scss';

import Novidades from '../../components/novidades/novidades';

export default function ModuloNovidades() {
  return (
    <React.Fragment>
      <div>Em Construção</div>
     {
      //<Novidades/>
     }
    </React.Fragment>
)}
