import React from 'react';
import './cadastrese.scss';
import CreateAccountForm from '../../components/create-account-form/CreateAccountForm';
import { DuoCard } from '../../layouts';

export default function ModuloCadastreSe() {
  return (
    
      <DuoCard title="Cadastro">
      <CreateAccountForm/>
      </DuoCard>
      
    
)}
