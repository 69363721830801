import React from 'react';
import './satesb.scss';
import ListDowns from '../../components/listdowns/listdowns';

export default function ModuloSatesb() {
  return (
    <React.Fragment>
      <div className='satesb'>


  <div
    style={{
      color: "rgb(73, 68, 64)",
      fontSize: "16px",
      overflowX: "hidden",
    }}>
   

    <div>
      <div>
        <main>  
          <div
            id="div-14"
            style={{
              margin: "auto",
              paddingLeft: "16px",
              paddingRight: "16px",
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: "-15px",
                marginRight: "-15px",
                marginTop: "3rem",
              }}>
              <div
                id="div-15"
                style={{
                  color: "rgb(54, 54, 54)",
                  flexBasis: "66.6667%",
                  maxWidth: "100%",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  width: "100%",
                }}>
                <div
                  id="div-16"
                  style={{
                    margin: "auto",
                    paddingRight: "16px",
                    width: "100%",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "-15px",
                    }}>
                      <div
            id="div-7"
            style={{
              backgroundColor: "rgb(238, 238, 238)",
            }}>
            <div
              id="div-8"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                minHeight: "420px",
                overflow: "hidden",
                position: "relative",
              }}>
              

              <div
                style={{
                  maxWidth: "100%",
                  width: "100%",
                }}>
                <div
                  id="div-11"
                  style={{
                    height: "100%",
                    margin: "auto",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    width: "100%",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "100%",
                      margin: "-15px",
                    }}>
                    <div
                      id="div-12"
                      style={{
                        flexBasis: "50%",
                        maxWidth: "100%",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        width: "100%",
                      }}>   
                      <h1
                        style={{
                          color: "rgb(54, 54, 54)",
                          fontSize: "32px",
                          fontWeight: "900",
                          marginBottom: "1rem",
                          paddingTop: "0.25rem",
                        }}>
                        SATESB da AOTI: conheça as principais funcionalidades
                      </h1>

                      <div
                        style={{
                          color: "rgb(54, 54, 54)",
                          fontSize: "14px",
                          marginTop: "3rem",
                          textTransform: "uppercase",
                        }}>
                        <span>Equipe AOTI | 25 MAIO, 2024 - Atualizado em 26 maio, 2024</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="div-13"
              style={{
                backgroundColor: "rgb(217, 217, 217)",
                color: "rgb(255, 255, 255)",
                height: "7px",
                width: "100%",
              }}>
              <div
                style={{
                  backgroundColor: "rgb(254, 172, 14)",
                  display: "flex",
                  flexDirection: "column",
                  height: "7px",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>
                    <div
                      id="div-17"
                      style={{
                        flexBasis: "100%",
                        marginBottom: "3rem",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        width: "100%",
                      }}>
                        
                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Você sabia que um SATESB pode ajudar toda a sua empresa a aumentar a produtividade? Quanto mais completo o sistema de contratado, mais tarefas são automatizadas e é por isso que o SATESB da AOTI é a melhor solução do mercado!
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Com o sistema, seus geólogos ganham mais tempo para focar no que realmente interessa. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        São muitos os módulos disponíveis para ajudá-lo a organizar o dia a dia de todos que trabalham com você e, dessa forma, otimizar processos, reduzir custos e aumentar a produtividade da empresa.
                      </p>

                      
                     
                        <img
                          src="img/DESENHO_POCO.jpg"
                          style={{
                            cursor: "pointer",
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      

                      <h2
                        id="h2-1"
                        style={{
                          fontSize: "30px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        O que é o SATESB da AOTI?
                      </h2>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        O SATESB da AOTI é um sistema técnico feito por geólogos para geologos desenvolvido para atender às necessidades do seu segmento. Para isso, a solução traz funcionalidades exclusivas para as áreas de desenho, mapa, calculos, quimica e muito mais.
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Por ser um sistema flexível, ele permite uma adequação à grande parte das necessidades do negócio, sem contar a possibilidade de integrações e novas personalizações do ambiente de trabalho. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Em consequência, a ferramenta atende diversas empresas, desde a agroindústria ao setor de serviços.
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Hoje, são mais de 400 geólogos aproveitando a flexibilidade do sistema e registrando 438 mil de acessos em módulos por ano.
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Se você entendeu o que é o (SATESB) ou "Sistema de Gestão Geológica" desenvolvido pela AOTI, percebe como essa solução completa e robusta pode potencializar os resultados e a competitividade da sua empresa, certo?
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Veja a seguir como ele funciona!
                      </p>

                      <h2
                        id="h2-2"
                        style={{
                          fontSize: "30px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Como funciona o SATESB?
                      </h2>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        O sistema de SATESB funciona
                        <strong
                          style={{
                            fontWeight: "700",
                          }}>
                          {" "}
                          de forma integrada
                        </strong>
                        , reunindo os dados de todas as áreas da empresa em um só lugar para facilitar a gestão e a tomada de decisões. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Ele é composto por vários módulos que atendem às necessidades específicas do seu segmento. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Você pode escolher quais módulos usar, além de optar por soluções complementares que podem aprimorar o seu sistema e muito mais. 
                      </p>

                      <h2
                        id="h2-3"
                        style={{
                          fontSize: "30px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Conheça as vantagens do SATESB da AOTI
                      </h2>

                      
                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        O sistema foi desenvolvido para que suas funcionalidades pudessem ser personalizadas e adaptadas à grande parte das necessidades da sua empresa. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        É possível desenvolver novas integrações e fazer personalizações do ambiente de trabalho de forma simples e ágil.
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Caso a sua empresa ainda não tenha muitos processos implantados, o{" "}
                        
                          sistema de SIGeo da AOTI
                        
                        também pode ser um grande aliado: por ser um produto que está há muito tempo no mercado, ele já traz consigo alguns métodos pré-definidos.
                        <em
                          style={{
                            fontStyle: "italic",
                          }}>
                           
                        </em>
                      </p>

                     

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Outros benefícios do SIGeo incluem:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          <strong
                            style={{
                              fontWeight: "700",
                            }}>
                            precisão nos indicadores de calculo:
                          </strong>{" "}
                          com soluções de calculos integradas, você tem mais segurança e assertividade nas informações;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          <strong
                            style={{
                              fontWeight: "700",
                            }}>
                            redução de erros e custos:
                          </strong>{" "}
                          a automatização de processos reduz o risco de a sua equipe cometer erros, o que diminui retrabalhos e problemas financeiros provocados por falhas, gerando economia de tempo e dinheiro;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          <strong
                            style={{
                              fontWeight: "700",
                            }}>
                            agilidade nos processos:{" "}
                          </strong>
                          os relatórios do sistema são completos e entregam informações atualizadas para um processo de tomada de decisões muito mais assertivo e ágil. 
                        </li>
                      </ul>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Além disso, outra vantagem fundamental do sistema é a simplicidade. Desenvolvido para conferir agilidade e produtividade ao dia a dia empresarial, os módulos e as atividades têm nomes simples exatamente para facilitar o trabalho das equipes.
                      </p>

                      

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        “
                        <em
                          style={{
                            fontStyle: "italic",
                          }}>
                          O que a gente percebe é que muitos geólogos são bons em campo(Execução) ou no que se entrende como parte pratica, porem quando se trata de fazer calculos e documentação isso deixa a desejar. Muitas faculdades publicas tem o foco na parte pratica quase nenhuma foca em calculo, exigencias técnica, métodos de execução e normas. Em muitos casos o geólogo acaba recorrendo a livros(Dificeis de Encontrar) onde muito tem formulas complexas que o geólogo nem sabe como executar, ou qual aplicar para sua necessidade. O SATESB da AOTI é um sistema que atende a todas essas exigências do Brasil.
                        </em>
                        ”, destaca Paulo Henrique(Diretor).
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Você também pode ficar tranquilo quanto à utilização do sistema: ele tem funcionalidades muito intuitivas, e você encontra diversos conteúdos e apresentações nosso{" "}
                        <a
                          href="https://www.youtube.com/@AOTIOFICIAL"
                          target='_blank'
                          style={{
                            color: "rgb(254, 172, 14)",
                          }}>
                          YOUTUBE
                        </a>{" "}
                        que ajudam a sua empresa a aprender mais sobre as ferramentas disponíveis.
                      </p>

                 

                      <h2
                        id="h2-4"
                        style={{
                          fontSize: "30px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Saiba mais sobre as funcionalidades do sistema
                      </h2>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        O sistema SATESB aborda os principais processos operacionais de um geólogo, mas o geólogo pode o contratar o sistema ou apenas módulos específicos, de acordo com o que a sua empresa mais precisa no momento. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Dentre as funcionalidades mais escolhidas pelas organizações, estão aquelas relacionadas a:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Desenho Contrutivo; Teste de Bombeamento; Teste Escalonado;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Calculos: Vazão; Rebaixamento; N.D; N.E; Transmissividade; Vazão Especifica; Rebaixamento Especifico; Delta(S); Condutividade Hidraulica; Curva Caracteristica do Poço; Coeficiente de Armazenamento e muito mais;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Graficos: Vazão, Rebaixamento; Escalonado; Logaritmos; Theis; Hantush; Jacob; Hantush/Jacob; Neuman; Cooper/Jacob; Dupuit/Forchheimer;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Autores: Cooper/Jacob; Hantush; Neuman; Theis; Hantush/Jacob; Dupuit/Forchheimer;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Mapa 3d de poços de todo brasil, Mapa IBGE, Mapa com detalhes de poços;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Analise Quimica da Agua; Documentação de Coleta; Graficos de PIPER;Balanço IONICO
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Bombas: nosso sistema contem um catalogo de bombas com graficos de curva para voce escolher a melhor bomba com a vazão necessaria para o negocio. Comparando com a profundidade, Vazão Requerida e capacidade de produção da mesma.
                        </li>
                      </ul>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Há ainda a flexibilidade em nosso sistema ele pode ser instalado em equipamentos para levar ao campo 
                        , com o programa funcionando 100% on premise(Instalado no seu computador) você pode trabalhar com ele até mesmo sem internet*.
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                       *ele depende da internet para fazer a instalação da licença, porem apos instalado pode usar o perido adquirido sem problemas.
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Conheça agora as principais ferramentas voltadas para o seu segmento e veja como elas podem ajudar a sua empresa a crescer!                        
                      </p>

                      <h3
                        id="h3-1"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Géologos
                      </h3>

                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/geo.jpeg"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Para os geólogos que desejam estar cada vez mais próximas das mudanças promovidas técnologia.
                        e ainda estão começando a ganhar maturidade, contratar um sistema é o primeiro passo que precisa ser dado. 
                      </p>

                      

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        No SATESB, você terá à disposição funcionalidades próprias para atender a parte de Ciclo de Vida de Poço ou serviço especifico. 
                      </p>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        O sistema também ajuda o geólogo a resolver alguns dos principais desafios, como os relacionados à redução contínua de custos, rastreabilidade e Documtenção, além do histórico.
                      </p>

                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Desenho
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/DESENHO_POCO.jpg"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de desenho que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Descrição Litologica; Descrição Construtiva;
                        </li>
                        <figure
                          style={{
                            marginBottom: "1rem",
                          }}>
                          <img
                            src="img/descricao_construtiva.png"
                            style={{
                              display: "initial",
                              height: "auto",
                              maxWidth: "100%",
                              width: "715px",
                            }}
                          />
                        </figure>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Desenho Litologico; Desenho Construtivo;
                        </li>
                        <figure
                          style={{
                            marginBottom: "1rem",
                          }}>
                          <img
                            src="img/desenho_fatiado.png"
                            style={{
                              display: "initial",
                              height: "auto",
                              maxWidth: "100%",
                              width: "715px",
                            }}
                          />
                        </figure>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Documentação construtiva, Material usado para construção; Material usado para revestimento; Material usado para filtro; Material usado para tampa; Material usado para base; Material usado para anel
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Detalhamento do avanço de perfuração
                        </li>
                        <figure
                          style={{
                            marginBottom: "1rem",
                          }}>
                          <img
                            src="img/avanco_perfuracao.png"
                            style={{
                              display: "initial",
                              height: "auto",
                              maxWidth: "100%",
                              width: "715px",
                            }}
                          />
                        </figure>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Método de Perfuração; Fluido; Volume; Cimentação, Filtro;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/kF7ZfIYS9Po?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                       
                      </ul>

                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Teste de Bombeamento
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/testebombeamento.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de teste que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Teste De Vazão, Teste de Recuperação e Teste Escalonado;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Autores: Cooper/Jacob; Hantush; Neuman; Theis; Hantush/Jacob; Dupuit/Forchheimer;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Calculos: Vazão; N.E, N.D; Transmissividade; Vazão Especifica; Rebaixamento Especifico; Delta(S); Condutividade Hidraulica; Curva Caracteristica do Poço; Coeficiente de Armazenamento e muito mais;
                        </li>
                        <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/calculos.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Graficos:
                        </li>
                        <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/grafico1.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                        <img
                          src="img/grafico2.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/grafico3.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                        <img
                          src="img/grafico4.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/grafico5.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                        <img
                          src="img/grafico6.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/grafico7.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                        <img
                          src="img/grafico8.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/O8MqM2AfuO8?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                        
                      </ul>

                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Boletim de SPT
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/BOLETIM_SPT.jpg"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de boletim de SPT que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Documentação de Amostras; Documentação de camadas
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Detalhe de Golpes/Penetração
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Ensaio Penetrometrico; Resistencia a Penetração
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Desenho: Litologico; Agua; Camadas;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Métodos Executivos; Trado;Circulação a agua;Revestimento; SPT;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Calculos 100% automatizados;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/9Pos4YzanYU?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                      </ul>

                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Analise Quimica
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/ANALISE_QUIMICA.jpg"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de analise quimica que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Documentação de Coleta de Agua; Laboratório;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Calculos automatizados; Balanço IONICO;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Graficos:
                        </li>
                        <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/grafico10.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                        <img
                          src="img/grafico11.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                      </figure>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Diagrama de Irrigação:
                        </li>

                        <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/grafico12.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/-i6jhyusHTg?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                      </ul>

                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Permeabilidade do Solo
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/PERMEABILIDADE_SOLO.jpg"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de Permeabilidade do solo que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Coeficiente de Permeabilidade do solo - Poço Raso;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Coeficiente de Permeabilidade do solo, acima do N.A por Rebaixamento;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Coeficiente de Permeabilidade do solo, acima do N.A por Proveta;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Calculos 100% automatizados;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Graficos;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/kyL85ZB9mWI?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                      </ul>
                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para MAPA
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/MAPA3D.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                        <img
                          src="img/MAPAIBGE.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "50%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/MAPASIAGAS.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>
                      

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de Mapa que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Mapa 3d de subsolo com objeto 3d para você visualizar poços no subsolo;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Mapa IBGE com irrigação;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Mapa tipo satelite com visualização de poços;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Clique no poço e obtenha dados como geologia, profundidade, vazão, data de perfuração e muito mais;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/1V8OKUGPuVQ?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                      </ul>

                      <h3
                        id="h3-2"
                        style={{
                          fontSize: "26px",
                          fontWeight: "500",
                          marginBottom: "0.5rem",
                          marginTop: "40px",
                        }}>
                        Funcionalidades para Relatórios
                      </h3>
                      <figure
                        style={{
                          marginBottom: "1rem",
                        }}>
                        <img
                          src="img/RELATORIOS.png"
                          style={{
                            display: "initial",
                            height: "auto",
                            maxWidth: "100%",
                            width: "715px",
                          }}
                        />
                      </figure>

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Alguns recursos de relatório que podem ser encontrados no SATESB são:
                      </p>

                      <ul
                        style={{
                          fontSize: "18px",
                          listStyleType: "disc",
                          marginBottom: "1rem",
                          paddingLeft: "30px",
                        }}>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Mais de 200 modelos de relatórios prontos para impressão;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Relatórios especificos para orgãos como IMASUL, SEMA, IPAAM, IGAM, CPRH, SIDAS, entre outros;
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Possibilidade de customizar relatórios para sua necessidade(dado já existente no sistema);
                        </li>

                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Layout integrado, você pode mudar cabeçalho e Rodapé de acordo com a sua necessidade;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                          Converta para PDF, Word e Excel;
                        </li>
                        <li
                          style={{
                            display: "list-item",
                          }}>
                           <figure
                              style={{
                              marginBottom: "1rem",
                              }}>
                              <div>
                                <iframe
                                  src="https://www.youtube.com/embed/3cn7s2_ky4c?feature=oembed"
                                  style={{
                                    height: "281px",
                                    maxWidth: "100%",
                                    width: "500px",
                                  }}
                                />
                              </div>
                            </figure>           
                        </li>
                      </ul>
                     



                     

                      <p
                        style={{
                          fontSize: "18px",
                          marginBottom: "1.2rem",
                        }}>
                        Quer contratar hoje mesmo uma das nossas soluções para a sua organização?{" "}
                        <a
                          href="/#comprar"
                          style={{
                            color: "rgb(254, 172, 14)",
                          }}>
                          Clique aqui
                        </a>
                        !
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </main>
      </div>
    </div>      
  </div>
</div>


    </React.Fragment>
)}
