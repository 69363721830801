import React from 'react';
import './suporte.scss';
import SuporteForm from '../../components/suporte-form/SuporteForm';


export default function ModuloSuporte() {
  return (
    <React.Fragment>
      
      <SuporteForm/>
     
   
  </React.Fragment>
)}
