import React from 'react';
import './comprar.scss';
import ListPlanos from '../../components/listplanos/listplanos';

export default function ModuloComprar() {
  return (
    <React.Fragment>
      <ListPlanos/>
    </React.Fragment>
)}
