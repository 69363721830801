import React from 'react';
import './download.scss';
import ListDowns from '../../components/listdowns/listdowns';

export default function ModuloDownload() {
  return (
    <React.Fragment>
      <ListDowns/>
    </React.Fragment>
)}
