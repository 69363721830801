import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Menu, { Item } from 'aoti-react/menu';
import { Button } from 'aoti-react/button';
import notify from 'aoti/ui/notify';
import axios from 'axios';
import DropDownButton, { DropDownButtonTypes } from 'aoti-react/drop-down-button';
//import './listdowns.scss';
import { ModuloDownload } from '../../pages';

interface IDownload {
  id: string;
  id_tbaoti_0017: string;
  data: string;
  hora: string;
  versao: string;
  descricao: string;
  url: string;
  id_tbaoti_0001: string;
  visivel_site: boolean;
  desc_app: string;
  tipo_app: string;
}

function formatDate(isoDate: string): string {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}



function Down({ down }: { down: IDownload }) {
  return <div
    id="div-7"
    style={{
      float: "left",
      height: "100%",
      maxWidth: "342px",
      minHeight: "1px",
      paddingLeft: "16px",
      paddingRight: "16px",
      width: "100%",
    }}>
    <div
      style={{
        minWidth: "266px",
        width: "100%",
      }}>
      <div
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          borderColor: "rgb(205, 216, 223)",
          borderRadius: "10px",
          borderStyle: "solid",
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          maxWidth: "100%",
          paddingBottom: "32px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "56px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}>
          <div
            style={{
              minHeight: "65px",
            }}>
            <h3
              id="h3-1"
              style={{
                color: "rgb(31, 32, 68)",
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "15px",
                minHeight: "60px",
                paddingRight: "40px",
              }}>
              {down.desc_app}
            </h3>
            <p
              id="p-1"
              style={{
                color: "rgb(31, 32, 68)",
                minHeight: "80px",
              }}>
              
              <strong
                style={{
                  fontWeight: "700",
                }}>
                Tipo
              </strong>{" "}
              {down.tipo_app}
            </p>
            <p
              id="p-1"
              style={{
                color: "rgb(31, 32, 68)",
                minHeight: "80px",
              }}>
              
              <strong
                style={{
                  fontWeight: "700",
                }}>
                Versão
              </strong>{" "}
              {down.versao}
            </p>
            <p
              id="p-1"
              style={{
                color: "rgb(31, 32, 68)",
                minHeight: "80px",
              }}>
              
              <strong
                style={{
                  fontWeight: "700",
                }}>
                Data
              </strong>{" "}
              {formatDate(down.data)}
            </p>
            <p
              id="p-1"
              style={{
                color: "rgb(31, 32, 68)",
                minHeight: "80px",
              }}>
              
              <strong
                style={{
                  fontWeight: "700",
                }}>
                Hora
              </strong>{" "}
              {down.hora}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}>
          <div
            id="div-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              minHeight: "101px",
              width: "100%",
            }}>
            
            
            
          </div>
          <a
            href={down.url}
            target="_blank"
            id="a-1"
            style={{
              backgroundColor: "rgb(46, 147, 238)",
              color: "rgb(255, 255, 255)",
              fontSize: "20px",
              fontWeight: "700",
              marginBottom: "30px",
              marginTop: "30px",
              paddingBottom: "22px",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "17px",
              textAlign: "center",
              width: "100%",
            }}>
            Download
          </a>
          
          
        </div>
      </div>
    </div>
      </div>
  ;
}

export default function ListDowns({ ...rest }) {
  
  const [downs, setDowns] = useState<IDownload[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API+'/downloads')
      .then(response => {
        setDowns(response.data);
      
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return <section className='ListDowns'
  id="section-1"
  style={{
    paddingBottom: "60px",
    width: "100%",
  }}>
  <div
    id="div-1"
    style={{
      backgroundColor: "rgb(246, 246, 249)",
      paddingBottom: "60px",
      paddingTop: "60px",
    }}>
    <h2
      id="h2-1"
      style={{
        color: "rgb(31, 32, 68)",
        fontSize: "46px",
        fontWeight: "700",
        marginBottom: "10px",
        marginLeft: "42px",
        marginRight: "42px",
        maxWidth: "940px",
        textAlign: "center",
      }}>
      Escolha um Produto
    </h2>
    
  </div>
 
  <div
    id="div-3"
    style={{
      alignItems: "center",        
        justifyContent: "space-between",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "visible",
        paddingLeft: "15px",
        paddingRight: "16px",
        position: "relative",
    }}>
    <div
      id="div-4"
      style={{
        width: "100%",
      }}>
      <div
        style={{
          position: "relative",
        }}>
        <div
          id="div-5"
          style={{
            margin: "-15px",
            overflow: "visible",
            position: "relative",
          }}>
          <div
            id="div-6"
            style={{
              display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(266px, 1fr))",
                gap: "15px",
                margin: "auto",
                maxWidth: "1200px",
            }}>


            
              {downs.map(down => ( 
               <Down key={down.id} down={down} />
            
              ))}
            
            
            
           
           
           
          </div>
        </div>
      </div>
    </div>
  </div>
 
</section>


;
}
