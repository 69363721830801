import defaultUser from '../utils/default-user';
import axios from 'axios';
import type { User, Cad,  TAccount} from '../types';


export async function signIn(email: string, password: string) : Promise<{isOk: boolean, data?: User, message?: string}> {
  //try {
    // Send request
    //basicAuth
    const token =  btoa(`${email}:${password}`);//Buffer.from(`${email}:${password}`, 'utf8').toString('base64');
    const heands = {
      headers: {
        'Authorization': `Basic ${token}`,
        'Content-Type': 'application/json' 
      }
    }
    
    try {
      const response = await axios.get(process.env.REACT_APP_API+'/login', heands )

        const User: User = {
          email: email,
          token: response.data.token,
          avatarUrl: ''
        };
        return {
          isOk: User.token !== '',
          data: User
        };
      
     
  
      

    } catch (err) {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    } 
 
}

export async function getUser() {
  try {   

    return {
      isOk: defaultUser.token !== '',
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function getAccount(token:string | undefined) : Promise<{isOk: boolean, data?: TAccount}> {

    
    if (token === undefined) {
      return {
        isOk: false,
      };
    }
    const heands = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
      }
    }
    
    try {
      const response = await axios.get(process.env.REACT_APP_API+'/user', heands )
       
        return {
          isOk: true,
          data: response.data
        };   
    } catch (err) {
      return {
        isOk: false,
      };
    }   
}

export async function createAccount(cad: Cad) {
  try {
    const heands = {
      headers: {
        'Content-Type': 'application/json' 
      }
      
    }
      await axios.post(process.env.REACT_APP_API+'/addcliente', {insert:cad},heands )

        return {
          isOk: true,
          message: "Cadastrado com sucesso"
        };    
  }  catch (err:any) {
    return {
      isOk: false,
      message: err.response.data.error
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  
     return {
      isOk: true,
      message: "ok"
    };
 
}

export async function RecoverPassword(email: string) {

  try {
    const heands = {
      headers: {
       
        'Content-Type': 'application/json' 
      }        
    }
    await axios.post(process.env.REACT_APP_API+'/recoverpass', {value:email},heands )

 

    return {
      isOk: true
    };    

    
  }  catch (err:any) {
    return {
      isOk: false,
      message: err.response.data.error
    };
  }

   
}

export async function ResetPassword(token:string,password: string) {

  try {
    const heands = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
      }        
    }
    await axios.post(process.env.REACT_APP_API+'/resetpass', {value:password},heands )

 

    return {
      isOk: true
    };    

    
  }  catch (err:any) {
    return {
      isOk: false,
      message: err.response.data.error
    };
  }

   
}
