import React from 'react';
import './recoverpass.scss';
import RecoverPasswordForm from '../../components/recover-password-form/RecoverPasswordForm';
import { DuoCard } from '../../layouts';

export default function ModuloRecoverPass() {
  return (
    
      <DuoCard title="Recuperação de Senha">
      <RecoverPasswordForm/>
      </DuoCard>
      
    
)}
