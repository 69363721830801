import React from 'react';
import './termo.scss';
import { DuoCard } from '../../layouts';


export default function ModuloTermo() {
  return (
    <React.Fragment>
      <DuoCard >
        <div className="termo">

        <h2>Termo de Uso - SATESB</h2>
    <p>Bem-vindo ao sistema SATESB. Este Termo de Uso regula a utilização do sistema de geologia SATESB. Ao utilizar o SATESB, você concorda com os termos e condições aqui estabelecidos.</p>

    <h2>Licenciamento</h2>
    <p>O SATESB é comercializado como uma licença por período de uso. As opções de licenciamento são:</p>
    <ul>
        <li>30 dias</li>
        <li>60 dias</li>
        <li>90 dias</li>
        <li>180 dias</li>
        <li>1 ano</li>
    </ul>
    <p>Cada licença permite o uso do SATESB com apenas um banco de dados. Caso a mesma licença seja utilizada com outro banco de dados, será necessário adquirir uma nova licença ou a licença existente poderá ser bloqueada.</p>

    <h2>Armazenamento de Dados</h2>
    <p>Todos os dados são 100% armazenados no cliente. Nós não temos acesso aos dados do cliente. O backup dos dados é de responsabilidade do cliente, exceto se for contratado um serviço específico para backup.</p>

    <h2>Suporte</h2>
    <p>O suporte para falhas e erros é gratuito. Para acessar o suporte, o cliente deve entrar em contato através dos canais fornecidos. Os prazos para resolução de problemas são:</p>
    <ul>
        <li>Até 30 dias para resolver problemas que não impedem o uso do sistema.</li>
        <li>Até 7 dias para resolver falhas que impedem o uso do sistema.</li>
    </ul>

    <h2>Responsabilidades do Cliente</h2>
    <p>O cliente é responsável por:</p>
    <ul>
        <li>Realizar backups regulares dos dados, salvo se contratado serviço de backup.</li>
        <li>Utilizar a licença adquirida apenas com o banco de dados autorizado.</li>
        <li>Relatar falhas e erros ao suporte de maneira oportuna.</li>
    </ul>

    <h2>Disposições Gerais</h2>
    <p>O uso do sistema SATESB implica na aceitação dos termos e condições aqui estabelecidos. Reservamo-nos o direito de alterar este Termo de Uso a qualquer momento, mediante aviso prévio ao cliente.</p>

    <p>Para mais informações ou dúvidas, entre em contato com nosso suporte.</p>

    <p>Obrigado por utilizar o SATESB.</p>
        </div>

      </DuoCard>
      
    
    </React.Fragment>
)}
