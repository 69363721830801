import React from 'react';

import './Slider.scss';





export default function Slider({ ...rest }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <a href="/#comprar">

      
        <figure style={{ width: "100%" }}
        >
          <img
            src="img/01-W1920-H1080VGradienteBlue_Black.png"
            alt=""
            style={{
              display: "initial",
              height: "auto",
              width: "100%",
              
            }}
          />
        </figure>
      </a>
    </div>
  );
}
