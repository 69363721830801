import React from 'react';
import './login.scss';
import LoginForm from '../../components/login-form/LoginForm';
import { SingleCard } from '../../layouts';


export default function ModuloLogin() {
  return (
    <React.Fragment>
      <SingleCard title="Login">
      <LoginForm/>
      </SingleCard>
      
    </React.Fragment>
)}
