import React from 'react';
import './imprensa.scss';
import ListDowns from '../../components/listdowns/listdowns';

export default function ModuloImprensa() {
  return (
<React.Fragment>
      <div>Em Construção</div>
    </React.Fragment>
)}
